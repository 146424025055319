.postBanner {
    object-fit: cover;
    width: 100%;
}

.mainContent {
    background-color: white;
    border-radius: 4px 4px 0 0;

}

#postFooter {
    background-color: #B4B4B4;
}

.author-image {
    border-radius: 50%;
    width: 52px;
    height: 52px;
}

.author-image-footer {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #d13333;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: -38px -6px, -14px 6px,  14px -6px;
    }
    33% {
      box-shadow: -38px 6px, -14px -6px,  14px 6px;
    }
    66% {
      box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    100% {
      box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
  }