/* styles for embedded text */
.embTxt {
}

/* styles for markdown */
.mdContainer > pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 6px;
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #d13333;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: -38px -6px, -14px 6px,  14px -6px;
    }
    33% {
      box-shadow: -38px 6px, -14px -6px,  14px 6px;
    }
    66% {
      box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    100% {
      box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
  }

  #postBody img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }